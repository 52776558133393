import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  DestinationEnum,
  DisplaysEnum,
  FIELD_COMPONENT,
  LinkDataPictureEnum,
  MODULE_NAME,
  PreviewControlEnum,
  ScreenNameEnum
} from 'app/config/constants';
import { DialogChangeTemplateForDisplayComponent } from 'app/dialog/dialog-change-template-for-display/dialog-change-template-for-display.component';
import { DialogChangeTemplateComponent } from 'app/dialog/dialog-change-template/dialog-change-template.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogPublishDataStationComponent } from 'app/dialog/dialog-publish-data-station/dialog-publish-data-station.component';
import { BusStop } from 'app/model/entity/bus-stop';
import { Common } from 'app/model/entity/common';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { Image } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { Route } from 'app/model/entity/route';
import { RouteBusStop } from 'app/model/entity/route-bustop';
import { StationCell } from 'app/model/entity/station-cell';
import { StationContentMediaFile } from 'app/model/entity/station-content-media-file';
import { Template } from 'app/model/entity/template';
import { SaveMainStateAction, SaveStationDisplayEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { BusStopService } from 'app/service/bus-stop.service';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { RouteBusStopService } from 'app/service/route-bus-stop.service';
import { RouteService } from 'app/service/route.service';
import { StationDrawService } from 'app/service/station-draw.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
@Component({
  selector: 'station-display-editor',
  templateUrl: './station-display-editor.component.html',
  styleUrls: ['./station-display-editor.component.scss']
})
export class StationDisplayEditorComponent implements OnInit, OnDestroy {
  Constant = Constant;
  DestinationEnum = DestinationEnum;
  DisplaysEnum = DisplaysEnum;
  @ViewChild('tableBody') tableBodyElement: ElementRef;
  /**
   * constants
   */
  readonly PREFIX_CELL_ID = 'cell';
  readonly DIV_DISPLAY_1 = 'div-display-1';
  readonly DIV_DISPLAY_2 = 'div-display-2';
  readonly FILE_OBJECT = 0;
  readonly FILE_MEDIA_OBJECT = 1;
  readonly URL_ATTRIBUTE = 'url';

  /**
   * true if open preview
   */
  isEnlargeScreen: boolean = true;
  /**
   * list all bus stop
   */
  busStops: Array<BusStop>;
  /**
   * list route by project
   */
  routes: Array<Route>;
  /**
   * projectId is opened
   */
  projectId: Number;
  /**
   * MODULE_NAME
   */
  MODULE_NAME: MODULE_NAME;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT: FIELD_COMPONENT;
  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * true if all row is checked
   */
  isCheckedAll: boolean;
  /**
   * cell selected
   */
  cellSelected: StationCell;
  /**
   * route bus stops
   */
  routeBusStops: Array<RouteBusStop>;
  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * true if select display 2
   */
  isDisplay2: boolean = false;
  /**
   * canvasContainerDisplay1
   */
  @ViewChild('canvasDisplay1', { static: false })
  canvasDisplay1: ElementRef;
  /**
   * canvasContainerDisplay2
   */
  @ViewChild('canvasDisplay2', { static: false })
  canvasDisplay2: ElementRef;
  /**
   * bus stop selected
   */
  busStopSelected: BusStop;
  /**
   * true if preview on
   */
  isPlay: boolean = false;
  /**
   * PreviewControlEnum
   */
  PreviewControlEnum = PreviewControlEnum;
  /**
   * list all cell
   */
  listAllCells: Array<StationCell> = new Array<StationCell>();
  /**
   * index preview display
   */
  indexPreview: number = 0;
  /**
   * true if next or previous control
   */
  isControlPreview: boolean;
  /**
   * type of template display 1 on screen
   */
  templateSelectedTypeDisplay1: DestinationEnum = DestinationEnum.MAIN;
  /**
   * type of template display 2 on screen
   */
  templateSelectedTypeDisplay2: DestinationEnum = DestinationEnum.MAIN;
  /**
   * array contain timeout for display 1
   */
  timeoutsDisplay1: any[] = [];
  /**
   * array contain timeout for display 2
   */
  timeoutsDisplay2: any[] = [];
  /**
   * list cell data to preview of a route
   */
  dataCellsPreview: Array<StationCell>;
  /**
   * saveDataSuccess
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * true if change data
   */
  isChangedData: boolean;
  /**
   * station content medias / files
   */
  stationContentMediaFiles: Array<StationContentMediaFile> = new Array<StationContentMediaFile>();
  /**
   * deleted cells
   */
  deletedCells: Array<StationCell> = new Array<StationCell>();
  /**
   * list file
   */
  filesData: any = [];
  /**
   * true if show vertical
   */
  isShowVertical: boolean = true;

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isEnlargeScreen: boolean;
    busStops: Array<BusStop>;
    routes: Array<Route>;
    isCheckedAll: boolean;
    cellSelected: StationCell;
    routeBusStops: Array<RouteBusStop>;
    isDisplay2: boolean;
    busStopSelected: BusStop;
    isPlay: boolean;
    listAllCells: Array<StationCell>;
    indexPreview: number;
    isControlPreview: boolean;
    dataCellsPreview: Array<StationCell>;
    isChangedData: boolean;
    stationContentMediaFiles: Array<StationContentMediaFile>;
    deletedCells: Array<StationCell>;
    tableBodyElement: ElementRef;
    isShowVertical: boolean;
  };
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private busStopService: BusStopService,
    private routeService: RouteService,
    private dialogService: DialogService,
    private actionService: MenuActionService,
    private routeBusStopService: RouteBusStopService,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private drawStationService: StationDrawService,
    public readonly store: Store<AppState>,
    private mediaService: MediaService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private executingService: ExecutingService
  ) {
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          this.saveData();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          this.deleteMediaOfCell();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          this.changeTemplate();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          // Code here
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeDisplay.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          this.changeDisplay();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionPublishData.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
          this.publishData();
        }
      })
    );
    this.subscriptions.push(
      this.drawStationService.toSwitchBetweenPage.subscribe((destinationDisplay: [string, DestinationEnum]) => {
        if (!this.isPlay) {
          return;
        }
        if (destinationDisplay[0] == Constant.CANVAS_DISPLAY_1_ID && this.busStopSelected?.display1Templates) {
          if (this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay1);
          }
          this.drawStationService.clearIntervalScrollListForDisplay1();
          this.handleEventClickAreaOnCanvas(
            this.canvasDisplay1,
            Constant.CANVAS_DISPLAY_1_ID,
            this.busStopSelected.display1Templates,
            destinationDisplay[1]
          );
        } else if (destinationDisplay[0] == Constant.CANVAS_DISPLAY_2_ID && this.busStopSelected?.display2Templates) {
          if (this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay2);
          }
          this.drawStationService.clearIntervalScrollListForDisplay2();
          this.handleEventClickAreaOnCanvas(
            this.canvasDisplay2,
            Constant.CANVAS_DISPLAY_2_ID,
            this.busStopSelected.display2Templates,
            destinationDisplay[1]
          );
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.stationDisplayEditorState?.stateOfComponent.isChangeLayout,
            isEnlargeScreen: componentState?.stationDisplayEditorState?.stateOfComponent.isEnlargeScreen,
            busStops: componentState?.stationDisplayEditorState?.stateOfComponent.busStops,
            routes: componentState?.stationDisplayEditorState?.stateOfComponent.routes,
            isCheckedAll: componentState?.stationDisplayEditorState?.stateOfComponent.isCheckedAll,
            cellSelected: componentState?.stationDisplayEditorState?.stateOfComponent.cellSelected,
            routeBusStops: componentState?.stationDisplayEditorState?.stateOfComponent.routeBusStops,
            isDisplay2: componentState?.stationDisplayEditorState?.stateOfComponent.isDisplay2,
            busStopSelected: componentState?.stationDisplayEditorState?.stateOfComponent.busStopSelected,
            isPlay: componentState?.stationDisplayEditorState?.stateOfComponent.isPlay,
            listAllCells: componentState?.stationDisplayEditorState?.stateOfComponent.listAllCells,
            indexPreview: componentState?.stationDisplayEditorState?.stateOfComponent.indexPreview,
            isControlPreview: componentState?.stationDisplayEditorState?.stateOfComponent.isControlPreview,
            dataCellsPreview: componentState?.stationDisplayEditorState?.stateOfComponent.dataCellsPreview,
            isChangedData: componentState?.stationDisplayEditorState?.stateOfComponent.isChangedData,
            stationContentMediaFiles: componentState?.stationDisplayEditorState?.stateOfComponent.stationContentMediaFiles,
            deletedCells: componentState?.stationDisplayEditorState?.stateOfComponent.deletedCells,
            tableBodyElement: componentState?.stationDisplayEditorState?.stateOfComponent.tableBodyElement,
            isShowVertical: componentState?.stationDisplayEditorState?.stateOfComponent.isShowVertical
          };
        })
    );
    this.subscriptions.push(
      this.drawStationService.onClickCanvas.subscribe((canvasDisplay: string) => {
        if (canvasDisplay == Constant.CANVAS_DISPLAY_1_ID && this.busStopSelected?.display1Templates) {
          this.clearTimeoutDisplay(this.timeoutsDisplay1);
          let display1 = this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1];
          this.drawDisplayDestinationIndex(display1, Constant.CANVAS_DISPLAY_1_ID);
        }
        if (canvasDisplay == Constant.CANVAS_DISPLAY_2_ID && this.busStopSelected?.display2Templates) {
          this.clearTimeoutDisplay(this.timeoutsDisplay2);
          let display2 = this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2];
          this.drawDisplayDestinationIndex(display2, Constant.CANVAS_DISPLAY_2_ID);
        }
      })
    );
    this.commonObject = commonService.getCommonObject();
  }

  async ngOnInit() {
    this.isDisplay2 = this.commonObject.isDisplay2Station;
    this.projectId = this.commonObject.projectId;
    if (!this.stateOfComponent?.isChangeLayout) {
      this.handleGetAllRouteBusStopsByProjectId();
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      this.handleGetAllBusStopsByProjectId();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    if (this.isDisplay2) {
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
    }
    this.stopAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveStationDisplayEditorStateAction({
        isChangeLayout: true,
        isEnlargeScreen: this.isEnlargeScreen,
        busStops: this.busStops,
        routes: this.routes,
        isCheckedAll: this.isCheckedAll,
        cellSelected: this.cellSelected,
        routeBusStops: this.routeBusStops,
        isDisplay2: this.isDisplay2,
        busStopSelected: this.busStopSelected,
        isPlay: this.isPlay,
        listAllCells: this.listAllCells,
        indexPreview: this.indexPreview,
        isControlPreview: this.isControlPreview,
        dataCellsPreview: this.dataCellsPreview,
        isChangedData: this.isChangedData,
        stationContentMediaFiles: this.stationContentMediaFiles,
        deletedCells: this.deletedCells,
        tableBodyElement: this.tableBodyElement,
        isShowVertical: this.isShowVertical
      })
    );
  }

  /**
   * handle after change layout
   */
  handleAfterChangeLayout(): void {
    this.isEnlargeScreen = this.stateOfComponent.isEnlargeScreen;
    this.busStops = this.stateOfComponent.busStops;
    this.routes = this.stateOfComponent.routes;
    this.isCheckedAll = this.stateOfComponent.isCheckedAll;
    this.cellSelected = this.stateOfComponent.cellSelected;
    this.routeBusStops = this.stateOfComponent.routeBusStops;
    this.isDisplay2 = this.stateOfComponent.isDisplay2;
    this.busStopSelected = this.stateOfComponent.busStopSelected;
    this.isPlay = this.stateOfComponent.isPlay;
    this.listAllCells = this.stateOfComponent.listAllCells;
    this.indexPreview = this.stateOfComponent.indexPreview;
    this.isControlPreview = this.stateOfComponent.isControlPreview;
    this.dataCellsPreview = this.stateOfComponent.dataCellsPreview;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.stationContentMediaFiles = this.stateOfComponent.stationContentMediaFiles;
    this.deletedCells = this.stateOfComponent.deletedCells;
    this.tableBodyElement = this.stateOfComponent.tableBodyElement;
    this.isShowVertical = this.stateOfComponent.isShowVertical;
    // draw canvas
    this.changeDetectorRef.detectChanges();
    this.scrollListPreview(this.busStopSelected, 'auto');
    // clear interval and time out list
    this.drawStationService.clearIntervalScrollListForDisplay1();
    this.drawStationService.clearIntervalScrollListForDisplay2();

    this.drawDisplay(
      Constant.CANVAS_DISPLAY_1_ID,
      this.canvasDisplay1,
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined
    );
    // draw area refer to selected no
    this.drawAreaReferToSelectedNo(
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined,
      Constant.CANVAS_DISPLAY_1_ID
    );
    if (this.isDisplay2) {
      this.drawDisplay(
        Constant.CANVAS_DISPLAY_2_ID,
        this.canvasDisplay2,
        this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined
      );
      // draw area refer to selected no
      this.drawAreaReferToSelectedNo(
        this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined,
        Constant.CANVAS_DISPLAY_2_ID
      );
    }
    if (this.isPlay) {
      this.playAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    }
  }

  /**
   * handle get all route bus stop by project id
   */
  handleGetAllRouteBusStopsByProjectId(): void {
    this.routeBusStopService.getRouteBusStopsByProjectId(this.projectId).subscribe(data => {
      this.routeBusStops = data;
    });
  }

  /**
   * handle get all bus stops by project id
   */
  handleGetAllBusStopsByProjectId(): void {
    this.routeService.getRoutesForStationDisplayEditorComponentByProjectId(this.projectId).subscribe(data => {
      this.routes = Helper.convertDataRoutesForStation(data);
      this.busStopService.getBusStopsForStationDisplayEditorComponent().subscribe(data => {
        this.busStops = Helper.convertDataBusStopsData(data);
        this.busStops.forEach(busStop => {
          busStop.stationCells = new Array<StationCell>();
          this.routes.forEach(route => {
            let busStopOfRoute = route.busStops.find(busStopData => busStopData.id == busStop.id);
            if (busStopOfRoute) {
              let cell = new StationCell(busStopOfRoute.orderNo + 1, busStopOfRoute.media, busStopOfRoute.id, route, busStopOfRoute);
              busStop.indexWords = busStopOfRoute.indexWords;
              busStop.stationCells.push(cell);
              this.listAllCells.push(cell);
            } else {
              busStop.stationCells.push(new StationCell());
            }
          });
        });
        if (this.busStops.length > 0) {
          this.selectBusStop(this.busStops[0], null, false);
        }
      });
    });
  }

  /**
   * enlarge the screen
   */
  enlargeScreen(): void {
    this.isEnlargeScreen = !this.isEnlargeScreen;
    if (this.isEnlargeScreen) {
      // calculate scale canvas for display 1
      if (this.busStopSelected?.display1Templates && this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]) {
        const divDisplay1 = document.getElementById(this.DIV_DISPLAY_1);
        this.calculateScaleTransformCanvas(
          this.canvasDisplay1,
          this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1],
          divDisplay1
        );
      }
      // calculate scale canvas for display 2
      if (this.busStopSelected?.display2Templates && this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2]) {
        const divDisplay2 = document.getElementById(this.DIV_DISPLAY_2);
        this.calculateScaleTransformCanvas(
          this.canvasDisplay2,
          this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2],
          divDisplay2
        );
      }
    }
  }
  /**
   * allow drop media
   * @param e
   * @param cell StationCell object
   */
  allowDrop(evt: DragEvent, cell: StationCell): void {
    if (!cell.no || this.isPlay) {
      evt.dataTransfer.effectAllowed = 'none';
      evt.dataTransfer.dropEffect = 'none';
      return;
    }
    evt.preventDefault();
  }

  /**
   * Drop media
   * @param e event
   * @param cell StationCell object
   */
  dropMedia(e, cell: StationCell): void {
    if (e.dataTransfer.getData(Constant.MEDIA_VALUE) == '' || !cell.no) {
      return;
    }
    e.preventDefault();
    var obj = JSON.parse(e.dataTransfer.getData(Constant.MEDIA_VALUE));
    let media = Helper.convertMediaData(obj);
    if (!Helper.isImage(media)) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Only picture is allowed.' } });
      return;
    }
    media.name = Helper.convertMediaNameBackWard(media.name, media.mediaNameEncode);
    // update list station content media files
    let stationContentMediaFile = new StationContentMediaFile(
      null,
      cell.busStop.busStopID,
      cell.route.routeID,
      cell.busStop.id,
      cell.route.id,
      media
    );
    if (cell?.media) {
      let cellMediaUrl = `${cell.media.name}.${cell.media.type}`;
      if (cellMediaUrl == `${stationContentMediaFile.media.name}.${stationContentMediaFile.media.type}`) {
        return;
      }
    }
    let indexMediaOfCell = this.stationContentMediaFiles?.findIndex(
      fileData => fileData.stopID == stationContentMediaFile.stopID && fileData.routeID == stationContentMediaFile.routeID
    );
    if (indexMediaOfCell != -1) {
      this.stationContentMediaFiles[indexMediaOfCell] = stationContentMediaFile;
    } else {
      this.stationContentMediaFiles.push(stationContentMediaFile);
    }
    let indexDeletedMedia = this.deletedCells?.findIndex(
      cellData => cellData.route.id === cell?.route?.id && cellData.busStopId === cell?.busStopId
    );
    if (indexDeletedMedia != -1) {
      this.deletedCells.splice(indexDeletedMedia, 1);
    }
    // handle draw cell
    cell.media = media;
    this.handleWhenDropMedia(cell);
  }

  /**
   * handle when drop media
   * @param cell StationCell object
   * @param media Media object
   */
  handleWhenDropMedia(cell: StationCell): void {
    this.isChangedData = true;
    if (cell === this.cellSelected) {
      // draw cell( areas have attribute: REFER TO SELECTED NO)
      this.drawCellOfBusStop();
    }
  }

  /**
   * draw cell of bus stop
   */
  drawCellOfBusStop(): void {
    if (this.busStopSelected?.display1Templates) {
      this.drawAreaReferToSelectedNo(
        this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1],
        Constant.CANVAS_DISPLAY_1_ID
      );
    }
    if (this.isDisplay2 && this.busStopSelected?.display2Templates) {
      this.drawAreaReferToSelectedNo(
        this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2],
        Constant.CANVAS_DISPLAY_2_ID
      );
    }
  }

  /**
   * check or uncheck all bus stops
   */
  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.busStops.forEach(busStop => (busStop.isChecked = this.isCheckedAll));
  }

  /**
   * check or uncheck a bus stop
   * @param index index of check-changed bus stop
   * @param event
   */
  changeChecked(index: number, event): void {
    event.stopPropagation();
    this.busStops[index].isChecked = !this.busStops[index].isChecked;
    this.isCheckedAll = this.busStops.every(busStop => busStop.isChecked);
  }

  /**
   * delete media of cell selected
   */
  deleteMediaOfCell(): void {
    if (!this.cellSelected || this.isPlay) {
      return;
    }
    if (this.cellSelected.media) {
      // show dialog
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to remove the image set for the selected cell?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.deletedCells.push(this.cellSelected);
            let index = this.stationContentMediaFiles?.findIndex(
              stationContentMediaFile =>
                stationContentMediaFile.routeId == this.cellSelected.route?.id &&
                stationContentMediaFile.busStopId == this.cellSelected.busStopId
            );
            if (index != -1) {
              this.stationContentMediaFiles.splice(index, 1);
            }
            this.cellSelected.media = null;
            this.isChangedData = true;
            // draw cell( areas have attribute: REFER TO SELECTED NO)
            this.drawCellOfBusStop();
          }
        }
      );
    }
  }

  /**
   * change template for bus stops
   */
  changeTemplate(): void {
    if (this.isPlay) {
      return;
    }
    var busStopSelecteds = this.busStops.filter(busStop => busStop.isChecked);
    if (busStopSelecteds.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select bus stop.' } });
      return;
    } else {
      let idMainPageDisplay1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idMainPage == busStopSelecteds[0].displayTemplate1.idMainPage)
          ? busStopSelecteds[0].displayTemplate1.idMainPage
          : null;
      let idSubPage1Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage1 == busStopSelecteds[0].displayTemplate1.idSubPage1)
          ? busStopSelecteds[0].displayTemplate1.idSubPage1
          : null;
      let idSubPage2Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage2 == busStopSelecteds[0].displayTemplate1.idSubPage2)
          ? busStopSelecteds[0].displayTemplate1.idSubPage2
          : null;
      let idSubPage3Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage3 == busStopSelecteds[0].displayTemplate1.idSubPage3)
          ? busStopSelecteds[0].displayTemplate1.idSubPage3
          : null;
      let idSubPage4Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage4 == busStopSelecteds[0].displayTemplate1.idSubPage4)
          ? busStopSelecteds[0].displayTemplate1.idSubPage4
          : null;
      let idSubPage5Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage5 == busStopSelecteds[0].displayTemplate1.idSubPage5)
          ? busStopSelecteds[0].displayTemplate1.idSubPage5
          : null;
      let idSubPage6Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage6 == busStopSelecteds[0].displayTemplate1.idSubPage6)
          ? busStopSelecteds[0].displayTemplate1.idSubPage6
          : null;
      let idSubPage7Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage7 == busStopSelecteds[0].displayTemplate1.idSubPage7)
          ? busStopSelecteds[0].displayTemplate1.idSubPage7
          : null;
      let idSubPage8Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage8 == busStopSelecteds[0].displayTemplate1.idSubPage8)
          ? busStopSelecteds[0].displayTemplate1.idSubPage8
          : null;
      let idSubPage9Display1 =
        busStopSelecteds[0].displayTemplate1 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate1?.idSubPage9 == busStopSelecteds[0].displayTemplate1.idSubPage9)
          ? busStopSelecteds[0].displayTemplate1.idSubPage9
          : null;

      let displayTemplate1 = idMainPageDisplay1
        ? new DisplayTemplate(
            idMainPageDisplay1,
            idSubPage1Display1,
            idSubPage2Display1,
            idSubPage3Display1,
            idSubPage4Display1,
            idSubPage5Display1,
            idSubPage6Display1,
            idSubPage7Display1,
            idSubPage8Display1,
            idSubPage9Display1
          )
        : new DisplayTemplate();

      let idMainPageDisplay2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idMainPage == busStopSelecteds[0].displayTemplate2.idMainPage)
          ? busStopSelecteds[0].displayTemplate2.idMainPage
          : null;
      let idSubPage1Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage1 == busStopSelecteds[0].displayTemplate2.idSubPage1)
          ? busStopSelecteds[0].displayTemplate2.idSubPage1
          : null;
      let idSubPage2Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage2 == busStopSelecteds[0].displayTemplate2.idSubPage2)
          ? busStopSelecteds[0].displayTemplate2.idSubPage2
          : null;
      let idSubPage3Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage3 == busStopSelecteds[0].displayTemplate2.idSubPage3)
          ? busStopSelecteds[0].displayTemplate2.idSubPage3
          : null;
      let idSubPage4Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage4 == busStopSelecteds[0].displayTemplate2.idSubPage4)
          ? busStopSelecteds[0].displayTemplate2.idSubPage4
          : null;
      let idSubPage5Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage5 == busStopSelecteds[0].displayTemplate2.idSubPage5)
          ? busStopSelecteds[0].displayTemplate2.idSubPage5
          : null;
      let idSubPage6Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage6 == busStopSelecteds[0].displayTemplate2.idSubPage6)
          ? busStopSelecteds[0].displayTemplate2.idSubPage6
          : null;
      let idSubPage7Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage7 == busStopSelecteds[0].displayTemplate2.idSubPage7)
          ? busStopSelecteds[0].displayTemplate2.idSubPage7
          : null;
      let idSubPage8Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage8 == busStopSelecteds[0].displayTemplate2.idSubPage8)
          ? busStopSelecteds[0].displayTemplate2.idSubPage8
          : null;
      let idSubPage9Display2 =
        busStopSelecteds[0].displayTemplate2 &&
        busStopSelecteds.every(busStopData => busStopData.displayTemplate2?.idSubPage9 == busStopSelecteds[0].displayTemplate2.idSubPage9)
          ? busStopSelecteds[0].displayTemplate2.idSubPage9
          : null;

      let displayTemplate2 = new DisplayTemplate(
        idMainPageDisplay2,
        idSubPage1Display2,
        idSubPage2Display2,
        idSubPage3Display2,
        idSubPage4Display2,
        idSubPage5Display2,
        idSubPage6Display2,
        idSubPage7Display2,
        idSubPage8Display2,
        idSubPage9Display2
      );
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogChangeTemplateComponent,
        {
          data: this.isDisplay2
            ? {
                screen: ScreenNameEnum.STATION_DISPLAY,
                displayTemplate1: displayTemplate1,
                displayTemplate2: displayTemplate2,
                isDisplay2: this.isDisplay2,
                dataExternalContent: []
              }
            : {
                screen: ScreenNameEnum.STATION_DISPLAY,
                displayTemplate1: displayTemplate1,
                isDisplay2: this.isDisplay2,
                dataExternalContent: []
              }
        },
        result => {
          this.isChangedData = false;
          if (result) {
            if (!this.isDisplay2) {
              busStopSelecteds.map(busStop => {
                busStop.displayTemplate1 = result;
              });
            } else {
              busStopSelecteds.map(busStop => {
                busStop.displayTemplate1 = result[0];
                busStop.displayTemplate2 = result[1];
              });
            }
            this.saveChangeTemplateForBusStops(busStopSelecteds);
          }
        }
      );
    }
  }

  /**
   * save data
   */
  async saveData() {
    // get station content files
    const stationContentFiles = this.stationContentMediaFiles?.filter(stationContentMediaFile => stationContentMediaFile.pcFileName);
    // get station content medias
    const stationContentMedias = this.stationContentMediaFiles?.filter(stationContentMediaFile => stationContentMediaFile.media);
    // get route bus stops is updated
    let routeBusStopUpdates: Array<RouteBusStop> = new Array<RouteBusStop>();
    this.deletedCells?.forEach(cell => {
      let routeBusStop = this.routeBusStops.find(rbs => rbs.routeId == cell.route.id && rbs.busStopId == cell.busStopId);
      if (routeBusStop) {
        routeBusStop.media = cell.media;
        routeBusStopUpdates.push(routeBusStop);
      }
    });
    // get data after save data success
    this.getResultResponseAfterCallApi(stationContentFiles, stationContentMedias).subscribe(async res => {
      const INDEX_OF_RESULT_RESPONSE_2 = 1;
      if (res[INDEX_OF_RESULT_RESPONSE_2].length > 0) {
        // update media of cells
        const mediasOutput = Helper.convertDataMediasData(res[INDEX_OF_RESULT_RESPONSE_2]);
        stationContentMedias.forEach((stationContentMedia, index) => {
          let indexOfMedia = this.listAllCells.findIndex(
            cell => cell?.route?.id == stationContentMedia.routeId && cell?.busStopId == stationContentMedia.busStopId
          );
          if (indexOfMedia != -1) {
            this.listAllCells[indexOfMedia].media = mediasOutput[index];
          }
        });
      }
      // update route bus stop of cell is deleted
      if (routeBusStopUpdates.length > 0) {
        await this.routeBusStopService.updateRouteBusStops(routeBusStopUpdates).toPromise();
      }
      this.resetValueAfterSaveDataSuccess();
    });
  }

  /**
   * get data after call api to update cell
   * @param stationContentFiles
   * @param stationContentMedias
   */
  getResultResponseAfterCallApi(stationContentFiles, stationContentMedias): Observable<any> {
    // api: udpate station content folder from pc and update cell
    const response1 = this.mediaService.updateStationContentFolderFromPCAndUpdateCell(this.filesData, stationContentFiles);
    // api: udpate station content folder from media manager and update cell
    const response2 = this.mediaService.uploadStationContentFoldeFromMediaManagerrAndUpdateCell(stationContentMedias);
    return forkJoin([response1, response2]);
  }

  /**
   * reset value after save data success
   */
  resetValueAfterSaveDataSuccess() {
    this.toast.success(Constant.SAVE_SUCCESS, '');
    this.deletedCells = new Array<StationCell>();
    this.stationContentMediaFiles = new Array<StationContentMediaFile>();
    this.filesData = [];
    this.isChangedData = false;
    this.saveDataSuccess.emit(true);
  }

  /**
   * drop media from PC
   * @param files Array FileMedia
   * @param cell StationCell object
   */
  filesDropped(files: any, cell: StationCell): void {
    if (!cell.no || this.isPlay) {
      return;
    }
    // check file dropped by type=> true if file is image
    if (!Helper.isImageFileStationDisplay(files[this.FILE_MEDIA_OBJECT])) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `This file format is not supported.`
        }
      });
      return;
    }
    // update list station content media files
    let stationContentMediaFile = new StationContentMediaFile(
      files[this.FILE_OBJECT][0].name,
      cell.busStop.busStopID,
      cell.route.routeID,
      cell.busStop.id,
      cell.route.id,
      null
    );
    let indexMediaOfCell = this.stationContentMediaFiles?.findIndex(
      fileData => fileData.stopID == stationContentMediaFile.stopID && fileData.routeID == stationContentMediaFile.routeID
    );
    if (indexMediaOfCell != -1) {
      this.stationContentMediaFiles[indexMediaOfCell] = stationContentMediaFile;
      this.filesData[indexMediaOfCell] = files[this.FILE_OBJECT][0];
    } else {
      this.stationContentMediaFiles.push(stationContentMediaFile);
      this.filesData.push(files[this.FILE_OBJECT][0]);
    }
    let indexDeletedMedia = this.deletedCells?.findIndex(
      cellData => cellData.route.id === cell?.route?.id && cellData.busStopId === cell?.busStopId
    );
    if (indexDeletedMedia != -1) {
      this.deletedCells.splice(indexDeletedMedia, 1);
    }
    // handle file to preview
    let media: Media = new Image();
    media.url = files[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    media.type = files[this.FILE_MEDIA_OBJECT]['type'];
    cell.media = media;
    this.handleWhenDropMedia(cell);
  }

  /**
   * select bus stop
   * @param busStop BusStop object
   * @param e
   * @param isUpdateTemplate true if template updated
   */
  selectBusStop(busStop: BusStop, e?: any, isUpdateTemplate?: boolean): void {
    if (!busStop || (!isUpdateTemplate && this.busStopSelected && busStop == this.busStopSelected)) {
      return;
    }
    if (e?.target.id.includes(this.PREFIX_CELL_ID)) {
      return;
    }
    this.resetStatePreview();
    this.busStopSelected = busStop;
    this.cellSelected = undefined;
    this.drawDisplay(
      Constant.CANVAS_DISPLAY_1_ID,
      this.canvasDisplay1,
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined
    );
    if (this.isDisplay2) {
      this.drawDisplay(
        Constant.CANVAS_DISPLAY_2_ID,
        this.canvasDisplay2,
        this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined
      );
    }
    let cell = this.busStopSelected.stationCells.filter(cell => cell.no)[0];
    this.selectCell(cell, this.busStopSelected);
  }

  /**
   * select cell
   * @param cell StationCell object
   * @param busStop BusStop
   */
  selectCell(cell: StationCell, busStop?: BusStop): void {
    if (!cell || (this.cellSelected && cell === this.cellSelected)) {
      return;
    }
    // reset state when click button repreview
    if (!this.isControlPreview || !cell?.route || cell.route?.id != this.cellSelected?.route?.id) {
      this.resetStatePreview();
    } else {
      this.stopAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    }
    this.cellSelected = cell;
    if (this.cellSelected.route) {
      this.handleGetCellsOfRoute(this.cellSelected.route);
      let index = this.dataCellsPreview.findIndex(cell => cell.busStopId == busStop?.id);
      this.indexPreview = index != -1 ? index : 0;
    } else {
      this.dataCellsPreview = [];
      this.indexPreview = 0;
    }

    let cellOldId = this.PREFIX_CELL_ID + this.busStopSelected?.id;
    let cellClickId = this.PREFIX_CELL_ID + busStop?.id;
    if (busStop && cellClickId != cellOldId) {
      this.busStopSelected = busStop;
      // clear interval and time out list
      this.drawStationService.clearIntervalScrollListForDisplay1();
      this.clearTimeoutDisplay(this.timeoutsDisplay1);

      this.drawDisplay(
        Constant.CANVAS_DISPLAY_1_ID,
        this.canvasDisplay1,
        this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined
      );
      if (this.isDisplay2) {
        this.drawStationService.clearIntervalScrollListForDisplay2();
        this.clearTimeoutDisplay(this.timeoutsDisplay2);
        this.drawDisplay(
          Constant.CANVAS_DISPLAY_2_ID,
          this.canvasDisplay2,
          this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined
        );
      }
    }
    if (this.templateSelectedTypeDisplay1 != DestinationEnum.MAIN || this.templateSelectedTypeDisplay2 != DestinationEnum.MAIN) {
      // clear interval and time out list
      if (this.templateSelectedTypeDisplay1 != DestinationEnum.MAIN) {
        this.drawStationService.clearIntervalScrollListForDisplay1();
        this.clearTimeoutDisplay(this.timeoutsDisplay1);
        this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
        this.drawDisplay(
          Constant.CANVAS_DISPLAY_1_ID,
          this.canvasDisplay1,
          this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined
        );
      }
      if (this.isDisplay2 && this.templateSelectedTypeDisplay2 != DestinationEnum.MAIN) {
        this.drawStationService.clearIntervalScrollListForDisplay2();
        this.clearTimeoutDisplay(this.timeoutsDisplay2);
        this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
        this.drawDisplay(
          Constant.CANVAS_DISPLAY_2_ID,
          this.canvasDisplay2,
          this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined
        );
      }
    }
    this.drawAreaReferToSelectedNo(
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined,
      Constant.CANVAS_DISPLAY_1_ID
    );
    if (this.isDisplay2) {
      this.drawAreaReferToSelectedNo(
        this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined,
        Constant.CANVAS_DISPLAY_2_ID
      );
    }
  }

  /**
   * draw display template
   * @param canvasDisplayId string (canvasDisplay1 or canvasDisplay2)
   * @param display Template
   */
  drawDisplay(canvasDisplayId: string, canvasDisplay: any, display: Template): void {
    this.changeDetectorRef.detectChanges();
    const canvasDisplayNode = document.getElementById(canvasDisplayId);
    Helper.clearNodeChild(canvasDisplayNode);
    if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
      this.drawStationService.stopAllVideoSoundDisplay1(this.busStopSelected, this.templateSelectedTypeDisplay1);
    } else {
      this.drawStationService.stopAllVideoSoundDisplay2(this.busStopSelected, this.templateSelectedTypeDisplay2);
    }
    if (!display) {
      return;
    }
    if (canvasDisplayNode) {
      // draw
      this.drawStationService.createCanvasTemplate(display, canvasDisplay, this.renderer);
      this.drawStationService.createAllCanvasAreaTemplate(display, canvasDisplay, this.renderer);
      const divDisplay =
        this.busStopSelected?.display1Templates && this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]
          ? document.getElementById(this.DIV_DISPLAY_1)
          : document.getElementById(this.DIV_DISPLAY_2);
      this.calculateScaleTransformCanvas(canvasDisplay, display, divDisplay);
      this.drawStationService.setupPreview(this.busStopSelected);
      this.drawStationService.drawPreviewStation(display, this.renderer, canvasDisplayId);
      // draw Area Refer To Selected No
      this.drawAreaReferToSelectedNo(display, canvasDisplayId);
    }
    if (this.isPlay) {
      this.drawDisplayDestinationIndex(display, canvasDisplayId);
    }
  }

  /**
   * change state preview
   */
  changeStatePreview(): void {
    this.isPlay = !this.isPlay;
    this.drawStationService.changeStatePlayPause(this.isPlay);
    if (this.isPlay) {
      if (
        this.busStopSelected?.display1Templates &&
        this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]?.isAutoTransition
      ) {
        let destinationIndex = this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]?.destination;
        let timeoutDisplay1 = setTimeout(() => {
          let display1Template = this.busStopSelected.display1Templates[destinationIndex];
          if (display1Template) {
            this.drawDisplay(
              Constant.CANVAS_DISPLAY_1_ID,
              this.canvasDisplay1,
              this.busStopSelected.display1Templates ? this.busStopSelected.display1Templates[destinationIndex] : undefined
            );
            this.templateSelectedTypeDisplay1 = destinationIndex;
            this.drawStationService.playAllVideoSoundDisplay1(this.busStopSelected, this.templateSelectedTypeDisplay1);
          }
        }, this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]?.transitionTime * 1000);
        this.timeoutsDisplay1.push(timeoutDisplay1);
      }
      if (
        this.isDisplay2 &&
        this.busStopSelected?.display2Templates &&
        this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2]?.isAutoTransition
      ) {
        let destinationIndex = this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2]?.destination;
        let timeoutDisplay2 = setTimeout(() => {
          let display2Template = this.busStopSelected.display2Templates[destinationIndex];
          if (display2Template) {
            this.drawDisplay(
              Constant.CANVAS_DISPLAY_2_ID,
              this.canvasDisplay2,
              this.busStopSelected.display2Templates ? this.busStopSelected.display2Templates[destinationIndex] : undefined
            );
            this.templateSelectedTypeDisplay2 = destinationIndex;
            this.drawStationService.playAllVideoSoundDisplay2(this.busStopSelected, this.templateSelectedTypeDisplay2);
          }
        }, this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2]?.transitionTime * 1000);
        this.timeoutsDisplay2.push(timeoutDisplay2);
      }
      this.playAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    } else {
      this.clearTimeoutDisplay(this.timeoutsDisplay1);
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
      this.pauseAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    }
  }

  /**
   * reset state preview
   */
  resetStatePreview(): void {
    this.isPlay = false;
    this.drawStationService.changeStatePlayPause(this.isPlay);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    if (this.isDisplay2) {
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
    }
    this.stopAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
  }

  /**
   * stop all media (sound, video)
   * @param destinationDisplay1 DestinationEnum
   * @param destinationDisplay2 DestinationEnum
   */
  private stopAllMedia(destinationDisplay1: DestinationEnum, destinationDisplay2: DestinationEnum): void {
    this.drawStationService.stopAllVideoSoundDisplay1(this.busStopSelected, destinationDisplay1);
    if (this.isDisplay2) {
      this.drawStationService.stopAllVideoSoundDisplay2(this.busStopSelected, destinationDisplay2);
    }
  }

  /**
   * pause all media (sound, video)
   * @param destinationDisplay1 DestinationEnum
   * @param destinationDisplay2 DestinationEnum
   */
  private pauseAllMedia(destinationDisplay1: DestinationEnum, destinationDisplay2: DestinationEnum): void {
    this.drawStationService.pauseAllVideoSoundDisplay1(this.busStopSelected, destinationDisplay1);
    if (this.isDisplay2) {
      this.drawStationService.pauseAllVideoSoundDisplay2(this.busStopSelected, destinationDisplay2);
    }
  }

  /**
   * play all media (sound, video)
   * @param destinationDisplay1 DestinationEnum
   * @param destinationDisplay2 DestinationEnum
   */
  private playAllMedia(destinationDisplay1: DestinationEnum, destinationDisplay2: DestinationEnum): void {
    this.drawStationService.playAllVideoSoundDisplay1(this.busStopSelected, destinationDisplay1);
    if (this.isDisplay2) {
      this.drawStationService.playAllVideoSoundDisplay2(this.busStopSelected, destinationDisplay2);
    }
  }

  /**
   * get cells of route
   * @param route Route object
   */
  handleGetCellsOfRoute(route: Route): void {
    this.dataCellsPreview = this.listAllCells.filter(cell => cell.route?.id == route.id);
    if (this.dataCellsPreview) {
      this.dataCellsPreview = this.dataCellsPreview
        .filter((value, index, arr) => arr.indexOf(value) === index)
        .sort(function(cell1, cell2) {
          return cell1.no - cell2.no;
        });
    }
  }

  /**
   * preview control
   * @param previewControlEnum PreviewControlEnum
   */
  previewControl(previewControlEnum: PreviewControlEnum): void {
    if (!this.isPlay) {
      return;
    }
    switch (previewControlEnum) {
      case PreviewControlEnum.RE_PREVIEW:
        if (this.indexPreview == 0) {
          this.resetStatePreview();
          // clear interval and time out list
          this.drawStationService.clearIntervalScrollListForDisplay1();
          this.drawStationService.clearIntervalScrollListForDisplay2();
          this.drawDisplay(
            Constant.CANVAS_DISPLAY_1_ID,
            this.canvasDisplay1,
            this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[DestinationEnum.MAIN] : undefined
          );
          if (this.isDisplay2) {
            this.drawDisplay(
              Constant.CANVAS_DISPLAY_2_ID,
              this.canvasDisplay2,
              this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[DestinationEnum.MAIN] : undefined
            );
          }
        } else {
          this.indexPreview = 0;
          this.isControlPreview = false;
        }
        break;
      case PreviewControlEnum.PREV:
        this.indexPreview--;
        this.isControlPreview = true;
        if (this.indexPreview <= 0) {
          this.indexPreview = 0;
        }
        break;
      case PreviewControlEnum.NEXT:
        if (this.dataCellsPreview && (this.dataCellsPreview.length == 0 || this.indexPreview >= this.dataCellsPreview.length - 1)) {
          return;
        }
        this.indexPreview++;
        this.isControlPreview = true;
        break;
      default:
        break;
    }
    if (this.dataCellsPreview) {
      let cell = this.dataCellsPreview[this.indexPreview];
      let busStop = this.busStops.find(busStop => busStop.id == cell?.busStopId);
      this.selectCell(cell, busStop);
      this.scrollListPreview(busStop, 'smooth');
    }
  }

  /**
   * scroll list preview
   * @param busStop
   */
  scrollListPreview(busStop: BusStop, behaviorType): void {
    const index = this.busStops.findIndex(busStopData => busStopData?.id == busStop?.id);
    if (index == -1) {
      return;
    }
    const element = _.get(this.tableBodyElement, `nativeElement.children[${index}]`, this.tableBodyElement);
    element.scrollIntoView({ behavior: behaviorType, block: 'center' });
  }

  /**
   * draw area attribute: REFER_TO_SELECTED_NO
   * @param display Template object
   * @param canvasDisplayId
   */
  drawAreaReferToSelectedNo(display: Template, canvasDisplayId: any): void {
    if (display) {
      // get areas is link picture and attribute: REFER_TO_SELECTED_NO
      let areasDisplay = Helper.getAllAreaTemplate(display).filter(
        area => !area.checkTypeTextArea() && !area.isFix && area.getArea().attribute == LinkDataPictureEnum.REFER_TO_SELECTED_NO
      );
      // draw cell
      this.drawStationService.drawCell(areasDisplay, this.renderer, this.cellSelected, canvasDisplayId);
    }
  }

  /**
   * change display
   */
  changeDisplay(): void {
    this.isDisplay2 = !this.isDisplay2;
    this.commonObject.isDisplay2Station = this.isDisplay2;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    if (this.busStops.length == 0) {
      return;
    }
    const divDisplay =
      this.busStopSelected?.display1Templates && this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]
        ? document.getElementById(this.DIV_DISPLAY_1)
        : document.getElementById(this.DIV_DISPLAY_2);
    this.calculateScaleTransformCanvas(
      this.canvasDisplay1,
      this.busStopSelected?.display1Templates[this.templateSelectedTypeDisplay1],
      divDisplay
    );
    if (this.isDisplay2) {
      this.changeDetectorRef.detectChanges();
      if (this.busStopSelected?.display2Templates) {
        this.drawDisplay(Constant.CANVAS_DISPLAY_2_ID, this.canvasDisplay2, this.busStopSelected.display2Templates[DestinationEnum.MAIN]);
      }
    }
  }

  /**
   * publish data
   */
  publishData(): void {
    if (this.isPlay) {
      return;
    }
    var busStopSelecteds = this.busStops.filter(busStop => busStop.isChecked);
    if (busStopSelecteds.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select bus stop.' } });
      return;
    }
    busStopSelecteds.forEach(busStop => {
      if (!busStop?.displayTemplate1) {
        busStop.displayTemplate1 = new DisplayTemplate(null);
      }
      if (!busStop?.displayTemplate2) {
        busStop.displayTemplate2 = new DisplayTemplate(null);
      }
    });
    if (busStopSelecteds.findIndex(busStop => !busStop.displayTemplate1.idMainPage && !busStop.displayTemplate2.idMainPage) != -1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'There is no data to publish.' } });
      return;
    }
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes and publish data?`,
            button1: 'Yes',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveData();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isChangedData = false;
                this.publishData();
              }
            });
          }
        }
      );
    } else {
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogPublishDataStationComponent,
        {
          data: {
            busStopSelecteds: busStopSelecteds
          }
        },
        result => {
          this.isChangedData = false;
        }
      );
    }
  }

  /**
   * clear all time out
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let index = 0; index < timeouts.length; index++) {
      clearTimeout(timeouts[index]);
    }
    timeouts = [];
  }

  /**
   * handle event click area on canvas
   */
  private handleEventClickAreaOnCanvas(
    canvasDisplay: any,
    canvasDisplayId: string,
    displayTemplates: Template[],
    destinationDisplayData: DestinationEnum
  ): void {
    if (displayTemplates && displayTemplates[destinationDisplayData]) {
      this.drawDisplay(canvasDisplayId, canvasDisplay, displayTemplates[destinationDisplayData]);
      if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
        this.templateSelectedTypeDisplay1 = destinationDisplayData;
        this.drawStationService.playAllVideoSoundDisplay1(this.busStopSelected, this.templateSelectedTypeDisplay1);
      } else {
        this.templateSelectedTypeDisplay2 = destinationDisplayData;
        this.drawStationService.playAllVideoSoundDisplay2(this.busStopSelected, this.templateSelectedTypeDisplay2);
      }
    }
  }

  /**
   * calculate scale transform canvas
   * @param canvasDisplayNode
   * @param display Template object
   * @param divDisplay
   */
  private calculateScaleTransformCanvas(canvasDisplay, display: Template, divDisplay): any {
    if (!divDisplay || !display) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    let h3Height =
      this.busStopSelected?.display1Templates && this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]
        ? document.getElementById('h3-template-name-display-1')
        : document.getElementById('h3-template-name-display-2');
    let boxHeight =
      this.busStopSelected?.display1Templates && this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1]
        ? document.getElementById('box-canvas-display-1')
        : document.getElementById('box-canvas-display-2');

    let divDisplay1Height = divDisplay.clientHeight - h3Height?.clientHeight - boxHeight?.clientHeight;
    let divDisplay1Width = divDisplay.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (display.width > divDisplay1Width) {
      scaleTransform.scaleX = divDisplay1Width / display.width;
    }
    if (display.height > divDisplay1Height) {
      scaleTransform.scaleY = divDisplay1Height / display.height;
    }
    let scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(canvasDisplay.nativeElement, 'transform', 'scale(' + scale + ')');
    canvasDisplay.nativeElement.style.left = (divDisplay1Width - display.width * scale) / 2 + 'px';
    canvasDisplay.nativeElement.style.top = (divDisplay1Height - display.height * scale) / 2 + 'px';
    return scale;
  }

  /**
   * draw display destination index (isAutoTransition)
   * @param display Template object
   * @param canvasDisplayId
   */
  drawDisplayDestinationIndex(display: Template, canvasDisplayId): void {
    let isCanvasDisplay1: boolean = canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID;
    if (display.isAutoTransition) {
      let destinationIndex = display.destination;
      let timeout = setTimeout(() => {
        let display1Template = this.busStopSelected?.display1Templates
          ? this.busStopSelected.display1Templates[destinationIndex]
          : undefined;
        let display2Template = this.busStopSelected?.display2Templates
          ? this.busStopSelected.display2Templates[destinationIndex]
          : undefined;
        if ((isCanvasDisplay1 && display1Template) || (!isCanvasDisplay1 && display2Template)) {
          if (isCanvasDisplay1) {
            // clear interval and time out list
            this.drawStationService.clearIntervalScrollListForDisplay1();
          } else {
            this.drawStationService.clearIntervalScrollListForDisplay2();
          }
          // draw display template
          this.drawDisplay(
            isCanvasDisplay1 ? Constant.CANVAS_DISPLAY_1_ID : Constant.CANVAS_DISPLAY_2_ID,
            isCanvasDisplay1 ? this.canvasDisplay1 : this.canvasDisplay2,
            isCanvasDisplay1 ? display1Template : display2Template
          );
          this.templateSelectedTypeDisplay1 = isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay1;
          this.templateSelectedTypeDisplay2 = !isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay2;
          if (isCanvasDisplay1) {
            this.drawStationService.playAllVideoSoundDisplay1(this.busStopSelected, this.templateSelectedTypeDisplay1);
          } else {
            this.drawStationService.playAllVideoSoundDisplay2(this.busStopSelected, this.templateSelectedTypeDisplay2);
          }
        }
      }, display.transitionTime * 1000);
      if (isCanvasDisplay1) {
        this.timeoutsDisplay1.push(timeout);
      } else {
        this.timeoutsDisplay2.push(timeout);
      }
    }
  }

  /**
   * show dialog template list by plus button
   * @param busStop
   * @param display
   */
  showDialogTemplateListByPlusButton(busStop: BusStop, display: DisplaysEnum): void {
    if (!busStop || this.isPlay) {
      return;
    }
    let displayTemplateOfBusStop = display == DisplaysEnum.DISPLAY_1 ? busStop.displayTemplate1 : busStop.displayTemplate2;
    let idMainPageDisplay = displayTemplateOfBusStop ? displayTemplateOfBusStop.idMainPage : null;
    let idSubPage1Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage1 : null;
    let idSubPage2Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage2 : null;
    let idSubPage3Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage3 : null;
    let idSubPage4Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage4 : null;
    let idSubPage5Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage5 : null;
    let idSubPage6Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage6 : null;
    let idSubPage7Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage7 : null;
    let idSubPage8Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage8 : null;
    let idSubPage9Display = displayTemplateOfBusStop ? displayTemplateOfBusStop.idSubPage9 : null;
    let displayTemplate = idMainPageDisplay
      ? new DisplayTemplate(
          idMainPageDisplay,
          idSubPage1Display,
          idSubPage2Display,
          idSubPage3Display,
          idSubPage4Display,
          idSubPage5Display,
          idSubPage6Display,
          idSubPage7Display,
          idSubPage8Display,
          idSubPage9Display
        )
      : new DisplayTemplate();
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeTemplateForDisplayComponent,
      {
        data: {
          display: display,
          displayTemplate: displayTemplate,
          screen: ScreenNameEnum.STATION_DISPLAY
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          if (display == DisplaysEnum.DISPLAY_1) {
            busStop.displayTemplate1 = result;
          } else {
            busStop.displayTemplate2 = result;
          }
          this.saveChangeTemplateForBusStop(busStop);
        }
      }
    );
  }

  /**
   * save change template display for bus stop
   * @param busStop
   */
  saveChangeTemplateForBusStop(busStop: BusStop): void {
    this.busStopService.updateTemplateForBusStop(Helper.convertDataBusStopForward(busStop)).subscribe(busStopData => {
      let busStop = Helper.convertDataBusStop(busStopData);
      let index = this.busStops.findIndex(bs => bs.id == busStop.id);
      if (index != -1) {
        this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
        this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
        this.busStops[index].display1Templates = busStop.display1Templates;
        this.busStops[index].display2Templates = busStop.display2Templates;
      }
      this.selectBusStop(this.busStopSelected, null, true);
    });
  }

  /**
   * save data change template
   * @param busStops list bus stop
   */
  saveChangeTemplateForBusStops(busStops: Array<BusStop>): void {
    let busStopsChanged = busStops.map(busStop => Helper.convertDataBusStopForward(busStop));
    this.busStopService.updateTemplatesForBusStops(busStopsChanged).subscribe(busStopsData => {
      this.isCheckedAll = true;
      this.checkAll();
      let busStopsOutput = Helper.convertDataBusStopsData(busStopsData);
      busStopsOutput.forEach(busStop => {
        let index = this.busStops.findIndex(busStopData => busStopData.id == busStop.id);
        if (index != -1) {
          this.busStops[index].display1Templates = busStop.display1Templates;
          this.busStops[index].display2Templates = busStop.display2Templates;
        }
      });
      this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
      this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
      this.selectBusStop(this.busStopSelected, null, true);
    });
  }

  /**
   * switch show horizontal/vertical
   */
  switchShowHorizontalVertical() {
    this.isShowVertical = !this.isShowVertical;
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.stopAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);

    this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
    this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
    // draw canvas
    this.changeDetectorRef.detectChanges();
    // clear interval and time out list
    this.drawStationService.clearIntervalScrollListForDisplay1();
    this.drawStationService.clearIntervalScrollListForDisplay2();

    this.drawDisplay(
      Constant.CANVAS_DISPLAY_1_ID,
      this.canvasDisplay1,
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1] : undefined
    );
    // draw area refer to selected no
    this.drawAreaReferToSelectedNo(
      this.busStopSelected?.display1Templates ? this.busStopSelected.display1Templates[this.templateSelectedTypeDisplay1] : undefined,
      Constant.CANVAS_DISPLAY_1_ID
    );
    this.drawDisplay(
      Constant.CANVAS_DISPLAY_2_ID,
      this.canvasDisplay2,
      this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2] : undefined
    );
    // draw area refer to selected no
    this.drawAreaReferToSelectedNo(
      this.busStopSelected?.display2Templates ? this.busStopSelected.display2Templates[this.templateSelectedTypeDisplay2] : undefined,
      Constant.CANVAS_DISPLAY_2_ID
    );
    if (this.isPlay) {
      this.playAllMedia(this.templateSelectedTypeDisplay1, this.templateSelectedTypeDisplay2);
    }
  }
}
